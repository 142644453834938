// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { RootContext } from 'contexts/RootContext';

// Utils
import { allStatusesChecked } from './utils/allStatusesChecked';
import { fm } from 'utils';
import { format } from 'date-fns';
import { translateStatus } from './utils/translateStatus';
import { getArrayOfInvoiceDocs } from './utils/getArrayOfInvoiceDocs';

// UI
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels';

// Styles
import styles from '../AccountData.module.css';

const PaymentsWithdrawal = ({ payments, royaltiesInvoice}) => {
	const { changePaymentStatus } = useContext(RootContext);

	const [accountPayments, setAccountPayments] = useState(payments);
	const [status, setStatus] = useState('');
	const [isBtnDisabled, setIsBtnDisabled] = useState(false);
	const [disabledPayments, setDisabledPayments] = useState(false);
	const [openAccordion, setOpenAccordion] = useState(null);

	const handleAccordionToggle = (index) => {
		setOpenAccordion(openAccordion === index ? null : index);
	};

	const submitPaymentStatusHandler = async () => {
		setIsBtnDisabled(true);
		setDisabledPayments(true);

		try {
			const promises = accountPayments
				.filter((payment) => payment.status === 'moderation')
				.map(async (payment) => {
					if (!payment.moderation_status) {
						const nullModerationStatus =
							'{"data":"0","signatures":"0","bank_data":"0"}';
						const moderationStatus = JSON.parse(nullModerationStatus);
						payment.moderation_status = JSON.stringify(moderationStatus);
					}

					const qualityData =
						JSON.parse(payment.moderation_status).data === '1' ? 1 : 0;
					const signatures =
						JSON.parse(payment.moderation_status).signatures === '1' ? 1 : 0;
					const bank_data =
						JSON.parse(payment.moderation_status).bank_data === '1' ? 1 : 0;

					const { data } = await changePaymentStatus(
						payment.id,
						qualityData,
						signatures,
						bank_data
					);
					return data;
				});

			const results = await Promise.all(promises);
			console.info(results);
		} catch (error) {
			console.error(error);
		}
	};

	const toggleModerationStatus = (paymentId, field) => (event) => {
		const value = event.currentTarget.checked ? '1' : '0';
		const updatedPayments = accountPayments.map((payment) => {
			if (payment.id === paymentId) {
				if (!payment.moderation_status) {
					const nullModerationStatus =
						'{"data":"0","signatures":"0","bank_data":"0"}';
					const moderationStatus = JSON.parse(nullModerationStatus);
					moderationStatus[field] = value;
					return {
						...payment,
						moderation_status: JSON.stringify(moderationStatus),
					};
				}

				if (payment.moderation_status) {
					const moderationStatus = JSON.parse(payment.moderation_status);
					moderationStatus[field] = value;
					return {
						...payment,
						moderation_status: JSON.stringify(moderationStatus),
					};
				}
			}

			return payment;
		});

		setAccountPayments(updatedPayments);

		if (
			allStatusesChecked(
				updatedPayments.filter((payment) => payment.status === 'moderation')
			)
		) {
			setStatus(fm('rod.account.payment.status.in_processing'));
		} else {
			setStatus(fm('rod.account.payment.status.rejected'));
		}
	};

	useEffect(() => {
		if (
			allStatusesChecked(
				accountPayments.filter((payment) => payment.status === 'moderation')
			)
		) {
			setStatus(fm('rod.account.payment.status.in_processing'));
		} else {
			setStatus(fm('rod.account.payment.status.rejected'));
		}

		const isPaymentsOnModeration = accountPayments.some(
			(payment) => payment.status === 'moderation'
		);
		if (isPaymentsOnModeration) {
			setIsBtnDisabled(false);
		} else {
			setIsBtnDisabled(true);
		}
	}, [accountPayments]);

	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.field.payments_withdrawal')}
					font="--gilroy-Medium-32"
				/>
			</div>
			{royaltiesInvoice && (
				<Label font="--gilroy-Medium-20">{fm('rod.main_page.balance')} : {royaltiesInvoice}</Label>
			)}
			<table className={styles.Table}>
				<thead>
					<tr>
						<th>{fm('rod.admin.account.files.document_type')}</th>
						<th>{fm('rod.admin.account.files.file')}</th>
						<th className="cb">{fm('rod.admin.account.payments.data')}</th>
						<th className="cb">{fm('rod.admin.account.contracts.signs')}</th>
						<th className="cb">{fm('rod.admin.account.payments.bank_data')}</th>
						<th className="cb">{fm('rod.admin.account.status_title')}</th>
					</tr>
				</thead>
				<tbody className={styles.paymentsTable}>
					{accountPayments.map((payment, index) => (
						<tr key={payment.id} style={{ verticalAlign: 'top' }}>
							<td>
								<FormattedMessage id="rod.account.payment.payment_type" />
							</td>
							<td
								style={{
									display: 'flex',
									height: openAccordion === index ? '125px' : '50px',
									overflowY: 'scroll',
								}}
							>
								<div style={{ position: 'relative' }}>
									<div
										onClick={
											accountPayments[index]?.invoice_documents &&
											Object.keys(accountPayments[index]?.invoice_documents)
												.length > 0
												? () => handleAccordionToggle(index)
												: () => {}
										}
										title={payment.title}
										className={styles.paymentTitle}
										style={{
											maxWidth: '300px',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											marginRight: '10px',
										}}
									>
										{accountPayments[index]?.invoice_documents &&
											Object.keys(accountPayments[index]?.invoice_documents)
												.length > 0 && (
												<>
													{openAccordion === index ? (
														<i className="fa fa-caret-up" />
													) : (
														<i className="fa fa-caret-down" />
													)}
												</>
											)}
										<span style={{ marginLeft: '3px' }}>{payment?.title}</span>
									</div>
									{openAccordion === index && (
										<div className={styles.invoiceDetails}>
											{getArrayOfInvoiceDocs(
												accountPayments[index]?.invoice_documents
											).map((invoice) => (
												<div className={styles.invoiceName}>
													<a
														key={invoice.id}
														className={styles.invoiceLink}
														href={invoice.url}
														target="_blank"
														rel="noopener noreferrer"
														title={invoice?.name}
													>
														{invoice?.name}
													</a>
												</div>
											))}
										</div>
									)}
								</div>
								<a
									href={payment.signed_url.url}
									target="_blank"
									rel="noreferrer"
									style={{
										marginLeft: 'auto',
									}}
								>
									<i className="fa fa-download" />
								</a>
							</td>
							<td className="cb">
								<div data-name={'01' + index}>
									<Checkbox
										className={styles.Checkbox}
										disabled={
											payment.status !== 'moderation' || disabledPayments
										}
										checked={
											payment.moderation_status &&
											(JSON.parse(payment.moderation_status).data === '1' ||
												JSON.parse(payment.moderation_status).quality === '1')
												? true
												: false
										}
										onChange={toggleModerationStatus(payment.id, 'data')}
									/>
								</div>
							</td>
							<td className="cb">
								<div data-name={'02' + index}>
									<Checkbox
										className={styles.Checkbox}
										disabled={
											payment.status !== 'moderation' || disabledPayments
										}
										checked={
											payment.moderation_status &&
											(JSON.parse(payment.moderation_status).signatures ===
												'1' ||
												JSON.parse(payment.moderation_status).signature === '1')
												? true
												: false
										}
										onChange={toggleModerationStatus(payment.id, 'signatures')}
									/>
								</div>
							</td>
							<td className="cb">
								<div data-name={'03' + index}>
									<Checkbox
										className={styles.Checkbox}
										disabled={
											payment.status !== 'moderation' || disabledPayments
										}
										checked={
											payment.moderation_status &&
											(JSON.parse(payment.moderation_status).bank_data ===
												'1' ||
												JSON.parse(payment.moderation_status).valid === '1')
												? true
												: false
										}
										onChange={toggleModerationStatus(payment.id, 'bank_data')}
									/>
								</div>
							</td>
							<td className="cb">
								<div data-name={'04' + index}>
									<span
										style={
											payment.status === 'rejected'
												? { color: 'var(--color-red)' }
												: {}
										}
									>
										{translateStatus(payment.status)},{' '}
										{format(new Date(payment.updated_at), 'dd.MM.yyyy')}
									</span>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			{accountPayments.length &&
			accountPayments.some((payment) => payment.status === 'moderation') ? (
				<div className={styles.paymentsStatus}>
					<div>
						<div>
							{fm('rod.admin.account.files.set_status')}
							{': '}
							{status}
						</div>
					</div>

					<Button
						primary
						text={
							isBtnDisabled
								? fm('rod.admin.account.files.sent')
								: fm('rod.admin.account.files.to_send')
						}
						disabled={isBtnDisabled}
						className={styles.Button}
						onClick={submitPaymentStatusHandler}
					/>
				</div>
			) : (
				''
			)}
		</section>
	);
};

export default PaymentsWithdrawal;
