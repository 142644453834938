// Core
import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Hooks
import { useClickOutside } from 'hooks';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';

// UI
import AdditionalRelease from '../AdditionalRelease/AdditionalRelease';
import { AutoWrapper, InfoTitle } from 'components';
import { FormInput } from 'components/Form/FormInput';
import { SwitchSave } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import { PerformerItem } from 'material-design/ReleaseCreate/PerformerItem';
import { InfoButton } from 'components/Buttons';

// Utils
import { checkFieldIsValid } from '../utils/checkFieldIsValid';
import { formatSalesDate } from 'utils/formatSalesDate';
import { checkSnippetErrors } from '../utils/checkSnippetErrors';
import { showError } from 'validators/showError';
import { accounts } from 'services';

// Icons
import close_button from 'images/close_button.svg';

// Styles
import s from '../Tracks.module.css';

const TracksForm = ({
	releaseTypeId,
	transferRelease,
	lastSavePerson,
	changeMask,
	isSnippedRender,
	changeSnippet,
	errors,
	setErrors,
	changeField,
	handleForm,
	toogleMenu,
	selectPerson,
	setSelectPerson,
	recordingSave,
	toogleLastPerson,
	takeByLastPerson,
	handleOnBlurDateInput,
	countries,
	handleISRC,
	handleCatalogNumber,
	additionalReleases,
	setAdditionalReleases,
	setShowRangeModal,
	isMainValid,
	releaseData,
	disableSaveBtn,
	handleChangeArtist,
	handleOpenModal,
	handleAddArtist,
	handleDeleteArtist,
	cleanErrorsField,
	isH11Edit,
	releaseStepPerformers,
}) => {
	const { isSlaPrivelegesForAccount } = useContext(AuthContext);
	const { lang } = useContext(LangContext);

	const title = useRef(null);
	const subtitle = useRef(null);
	const created_date = useRef(null);
	const genres = useRef(null);
	const created_country_id = useRef(null);
	const isrc = useRef(null);
	const catalog_number = useRef(null);
	const main_performer = useRef(null);
	const featured_artist = useRef(null);
	const party_id = useRef(null);
	const producer = useRef(null);
	const snippet_start = useRef(null);

	const fieldRefs = {
		title,
		subtitle,
		created_date,
		genres,
		created_country_id,
		isrc,
		catalog_number,
		main_performer,
		featured_artist,
		party_id,
		producer,
		snippet_start,
	};

	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [additionalErrors, setAdditionalErrors] = useState({});
	const [showTip, setShowTip] = useState(false);
	const [showSubtitleTip, setShowSubtitleTip] = useState(false);

	useClickOutside(title, () => setShowTip(false));
	useClickOutside(subtitle, () => setShowSubtitleTip(false));

	const disableTitleCondition =
		releaseTypeId === 70 ||
		releaseTypeId === 51 ||
		releaseTypeId === 42 ||
		releaseTypeId === 43;
	const disableSubtitleCondition =
		(releaseTypeId === 70 ||
			releaseTypeId === 51 ||
			releaseTypeId === 42 ||
			releaseTypeId === 43) &&
		releaseData.subtitle;

	const checkOnValid = () => {
		return additionalReleases.every((additionalRelease) =>
			checkFieldIsValid(
				additionalRelease,
				setAdditionalErrors,
				transferRelease,
				isSlaPrivelegesForAccount
			)
		);
	};

	const onSaveHandler = async () => {
		if (!additionalReleases.length) recordingSave(toogleMenu);

		if (
			releaseTypeId !== 51 &&
			releaseTypeId !== 2 &&
			releaseTypeId !== 64 &&
			releaseTypeId !== 69 &&
			selectPerson.additional
		) {
			setShowRangeModal(true);
		} else {
			if (isMainValid(selectPerson, true)) return;

			setButtonDisabled(true);
			try {
				const savePromises = additionalReleases.map(
					async (additionalRelease) => {
						if (
							checkFieldIsValid(
								additionalRelease,
								setAdditionalErrors,
								transferRelease,
								isSlaPrivelegesForAccount
							)
						) {
							additionalRelease.sale_start_date = formatSalesDate(
								additionalRelease.sale_start_date
							);

							if (typeof additionalRelease.id === 'string') {
								await accounts.createAdditionalRelease(additionalRelease);
							} else {
								await accounts.updateAdditionalRelease(additionalRelease);
							}
						}
					}
				);

				await Promise.all(savePromises);
				setButtonDisabled(false);

				if (checkOnValid()) {
					recordingSave(toogleMenu);
				}
			} catch (error) {
				setErrors(error.response.data.errors);
				setButtonDisabled(false);
			}
		}
	};

	useEffect(() => {
		const errorFields = Object.keys(errors);
		if (errorFields.length > 0) {
			for (const field of errorFields) {
				if (fieldRefs[field]?.current) {
					fieldRefs[field].current.scrollIntoView({ behavior: 'smooth' });
					break;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);

	return (
		<div className={s.form}>
			<img
				src={close_button}
				className={s.form__close}
				onClick={() => handleForm(toogleMenu)}
				alt=""
			/>

			{lastSavePerson && Object.keys(lastSavePerson).length !== 0 && (
				<div className={s.lastSave}>
					<div className={s.lastSave__switch}>
						<SwitchSave
							checked={takeByLastPerson}
							onChange={(_, c) => toogleLastPerson(c)}
						/>
					</div>
					<span>
						<FormattedMessage
							id={'rod.release.create.step.recording_info.form.save'}
						/>
					</span>
				</div>
			)}

			<span className={s.form__title} id="general">
				<FormattedMessage id={'rod.release.tab.main'} />
			</span>
			<div className={`${s.form__section} ${s.first__section}`}>
				<div className={s.input_leftCol} ref={title}>
					<FormInput
						type="muiInput"
						name={'title'}
						maxRows={4}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.name'}
							/>
						}
						disabled={disableTitleCondition}
						required
					/>
					{disableTitleCondition && (
						<InfoButton
							className={s.infoActive}
							style={{
								marginLeft: lang === 'ru' ? '8px' : '-28px',
							}}
							onClick={() => setShowTip(!showTip)}
						/>
					)}
					{showTip && (
						<div className={s.tip}>
							<FormattedMessage
								id={'rod.release.create.step.track.title.tip'}
							/>
						</div>
					)}
					{errors && Object.keys(errors).includes('title') && (
						<span className={s.Helper}>
							{typeof errors.title === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : errors?.title?.[0] === 'does not match' ? (
								<FormattedMessage
									id={`rod.release_create.error.title.does not match`}
								/>
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_rightCol} ref={subtitle}>
					<FormInput
						type="muiInput"
						name={'subtitle'}
						maxRows={4}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.subtitle'}
							/>
						}
						disabled={disableSubtitleCondition}
					/>
					{disableSubtitleCondition && (
						<InfoButton
							className={s.infoActive}
							style={
								selectPerson.subtitle
									? {
											marginLeft: lang === 'ru' ? '60px' : '-14px',
									  }
									: {
											marginTop: '22px',
											marginLeft: lang === 'ru' ? '16px' : '0px',
									  }
							}
							onClick={() => setShowSubtitleTip(!showSubtitleTip)}
						/>
					)}
					{showSubtitleTip && (
						<div className={s.tip}>
							<FormattedMessage
								id={'rod.release.create.step.track.subtitle.tip'}
							/>
						</div>
					)}
					{errors && Object.keys(errors).includes('subtitle') && (
						<span className={s.Helper}>
							{typeof errors.subtitle === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : errors?.subtitle?.[0] === 'does not match' ? (
								<FormattedMessage
									id={`rod.release_create.error.subtitle.does not match`}
								/>
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_rightCol} ref={created_date}>
					<FormInput
						type={'muiInputDate_v2'}
						readOnly={false}
						name={'created_date'}
						onChange={changeField}
						handleOnBlurDateInput={handleOnBlurDateInput}
						maxDate={true}
						errors={Object.keys(errors).includes('created_date') ? errors : {}}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.create_date'}
							/>
						}
						required
					/>
					{errors && Object.keys(errors).includes('created_date') && (
						<span className={s.Helper}>
							{
								<FormattedMessage
									id={`rod.error.${errors.created_date[0].rule}`}
								/>
							}
						</span>
					)}
				</div>
				<div className={s.input_leftCol} ref={genres}>
					<FormInput
						type={'muiAutocompleteMultipleGenres'}
						name={'genres'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						required
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.genre'}
							/>
						}
					/>
					{Object.keys(errors).includes('genres') && (
						<>
							{errors.genres[0].rule !== 'max_size' ? (
								<span className={s.Helper}>
									{
										<FormattedMessage
											id={`rod.release_create.error.required`}
										/>
									}
								</span>
							) : (
								<span className={s.Helper}>
									{errors.genres[0] && (
										<FormattedMessage
											id={'rod.error.max_size.genres'}
											values={{ value: errors.genres[0]?.value }}
										/>
									)}
								</span>
							)}
						</>
					)}
				</div>
				<div
					className={`${s.input_rightCol} ${s.input_country}`}
					ref={created_country_id}
				>
					<FormattedMessage
						id={'rod.release.create.step.recording_info.label.create_country'}
					>
						{(placeholder) => (
							<FormInput
								type="country"
								name="created_country_id"
								onChange={changeField}
								errors={errors}
								data={selectPerson}
								placeholder={placeholder}
								items={countries}
								countries={countries}
								required
								customError
							/>
						)}
					</FormattedMessage>
					{errors && Object.keys(errors).includes('created_country_id') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>
				<div></div>
				<div
					className={
						releaseData.step.includes('h11')
							? `${s.input_leftCol} ${s.disabled}`
							: s.input_leftCol
					}
					ref={isrc}
				>
					<InputMask
						mask={'aa-***-99-99999'}
						name={'isrc'}
						onChange={changeMask}
						errors={errors}
						value={selectPerson.isrc ? selectPerson.isrc : ''}
						disabled={
							(selectPerson.generate_isrc && !transferRelease) ||
							releaseData.step.includes('h11')
								? true
								: false
						}
					>
						{() => (
							<TextFieldInput
								variant="standard"
								type="muiInput"
								name={'isrc'}
								error={Object.keys(errors).length > 0 && errors['isrc']}
								value={selectPerson.isrc ? selectPerson.isrc : ''}
								label="ISRC"
								required
								disabled={
									(selectPerson.generate_isrc && !transferRelease) ||
									releaseData.step.includes('h11')
										? true
										: false
								}
							/>
						)}
					</InputMask>

					{errors && Object.keys(errors).includes('isrc') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>
				{!transferRelease && !releaseData.step.includes('h11') && (
					<AutoWrapper
						onClick={() => handleISRC(selectPerson?.generate_isrc)}
						check={selectPerson?.generate_isrc}
					/>
				)}
				<div className={s.input_leftCol} ref={catalog_number}>
					<FormInput
						type="muiInput"
						name={'catalog_number'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						required={!transferRelease && !releaseData?.step?.includes('h11')}
						disabled={
							selectPerson.generate_catalog_number ||
							releaseData?.step?.includes('h11')
						}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.catalog_number'}
							/>
						}
					/>
					{errors && Object.keys(errors).includes('catalog_number') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>

				{!transferRelease && !releaseData?.step?.includes('h11') && (
					<AutoWrapper
						onClick={() =>
							handleCatalogNumber(selectPerson?.generate_catalog_number)
						}
						check={selectPerson.generate_catalog_number}
					/>
				)}
			</div>

			<div className={s.form__section}>
				<div className={s.input_leftCol}>
					<span className={s.artist_title}>
						<FormattedMessage id={'rod.release.create.step.tracks.artists'} />
					</span>
				</div>
				<div className={s.input_rightCol}>
					<span className={`${s.artist_title} ${s.show}`}>
						<FormattedMessage
							id={'rod.release.create.step.tracks.featured_artists'}
						/>
					</span>
				</div>
			</div>
			<div className={`${s.form__section} ${s.artist__section}`}>
				<div className={s.input_leftCol}>
					<div className={s.artistsBlock} ref={main_performer}>
						{selectPerson.main_performer &&
							selectPerson.main_performer.length > 0 &&
							selectPerson.main_performer.map((performer, index) => (
								<PerformerItem
									key={index}
									performer={performer}
									index={index}
									lastIndex={selectPerson.main_performer.length - 1}
									personal={selectPerson}
									handleChangeArtist={handleChangeArtist}
									errors={errors}
									cleanErrorsField={() => {}}
									handleOpenModal={handleOpenModal}
									handleAddArtist={handleAddArtist}
									handleDeleteArtist={handleDeleteArtist}
									styles={{ width: '100%' }}
									dataField="main_performer"
									isH11Edit={isH11Edit}
									releaseStepPerformers={releaseStepPerformers}
									tracksForm
								/>
							))}
						{errors &&
							Object.keys(errors).includes('main_performer') &&
							errors['main_performer'][0] === 'does not match' &&
							releaseStepPerformers?.length > selectPerson.main_performer.length - 1 && (
								<span className={s.errorHelper}>
									{showError('main_performer')(errors['main_performer'][0])}
								</span>
							)}
					</div>
				</div>
				<div className={s.input_rightCol}>
					<div className={`${s.input_rightCol} ${s.hide}`}>
						<span className={s.artist_title}>
							<FormattedMessage
								id={'rod.release.create.step.tracks.featured_artists'}
							/>
						</span>
					</div>
					<div className={s.artistsBlock} ref={featured_artist}>
						{selectPerson.featured_artist &&
							selectPerson.featured_artist.length > 0 &&
							selectPerson.featured_artist.map((performer, index) => (
								<PerformerItem
									key={index}
									performer={performer}
									index={index}
									lastIndex={selectPerson.featured_artist.length - 1}
									personal={selectPerson}
									handleChangeArtist={handleChangeArtist}
									errors={errors}
									cleanErrorsField={cleanErrorsField}
									handleOpenModal={handleOpenModal}
									handleAddArtist={handleAddArtist}
									handleDeleteArtist={handleDeleteArtist}
									styles={{ width: '100%' }}
									label="rod.release.create.step.release.label.artists.featured_performer"
									dataField="featured_artist"
									isH11Edit={isH11Edit}
								/>
							))}
					</div>
				</div>
			</div>

			<span className={s.form__title}>
				<FormattedMessage id={'rod.release.create.step.tracks.rightholders'} />
			</span>
			<div className={`${s.form__section} ${s.second__section}`}>
				<div className={s.input_leftCol} ref={party_id}>
					<FormInput
						type="muiInput"
						name={'party_id'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.label'}
							/>
						}
						required={!selectPerson.producer}
					/>
					{errors && Object.keys(errors).includes('party_id') && (
						<span className={s.Helper}>
							{typeof errors.party_id === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('party_id')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_rightCol} ref={producer}>
					<FormInput
						type="muiInput"
						name={'producer'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={<FormattedMessage id={`rod.release.create.producer`} />}
						required={!selectPerson.party_id}
					/>
					{errors && Object.keys(errors).includes('producer') && (
						<span className={s.Helper}>
							{typeof errors.producer === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
			</div>

			{isSnippedRender && (
				<>
					<InfoTitle
						title="rod.release.create.step.tracks.fragment.title"
						helpTitle="rod.release.create.step.tracks.fragment.help"
					/>
					<div className={`${s.form__section} ${s.third__section}`}>
						<div className={s.input} ref={snippet_start}>
							<InputMask
								mask={'99:99:999'}
								maskChar={'0'}
								name={'snippet_start'}
								onChange={changeSnippet('snippet_start')}
								errors={errors}
								disabled={releaseData.step === 'h11'}
								value={
									selectPerson.snippet_start ? selectPerson.snippet_start : ''
								}
							>
								{() => (
									<TextFieldInput
										type="muiInput"
										variant="standard"
										name={'snippet_start'}
										required={releaseData.step !== 'h11'}
										errors={errors}
										disabled={releaseData.step === 'h11'}
										value={
											selectPerson.snippet_start
												? selectPerson.snippet_start
												: ''
										}
										label={
											<FormattedMessage
												id={'rod.release.create.step.tracks.fragment.start'}
											/>
										}
									/>
								)}
							</InputMask>
							{errors && Object.keys(errors).includes('snippet_start') && (
								<span className={s.Helper}>
									{checkSnippetErrors('snippet_start', errors, releaseTypeId)}
								</span>
							)}
						</div>
						<div className={s.input}>
							<InputMask
								mask={'99:99:999'}
								maskChar={'0'}
								name={'snippet_end'}
								onChange={changeSnippet('snippet_end')}
								errors={errors}
								disabled={releaseData.step === 'h11'}
								value={selectPerson.snippet_end ? selectPerson.snippet_end : ''}
							>
								{() => (
									<TextFieldInput
										type="muiInput"
										variant="standard"
										name={'snippet_end'}
										errors={errors}
										disabled={releaseData.step === 'h11'}
										value={
											selectPerson.snippet_end ? selectPerson.snippet_end : ''
										}
										label={
											<FormattedMessage
												id={'rod.release.create.step.tracks.fragment.end'}
											/>
										}
									/>
								)}
							</InputMask>
							{errors && Object.keys(errors).includes('snippet_end_start') && (
								<span className={s.Helper}>
									<FormattedMessage
										id={'rod.release_create_snipped.date_format'}
									/>
								</span>
							)}
						</div>
					</div>
					{errors && Object.keys(errors).includes('snippet_end') && (
						<span className={s.snippetError}>
							{checkSnippetErrors('snippet_end', errors, releaseTypeId)}
						</span>
					)}
				</>
			)}

			{!transferRelease &&
				(releaseTypeId === 51 ||
					releaseTypeId === 2 ||
					releaseTypeId === 64 ||
					releaseTypeId === 69 ||
					selectPerson.additional) &&
				!releaseData.step.includes('h11') && (
					<AdditionalRelease
						releaseTypeId={releaseTypeId}
						additionalReleases={additionalReleases}
						setAdditionalReleases={setAdditionalReleases}
						selectPerson={selectPerson}
						setSelectPerson={setSelectPerson}
						errors={errors}
						setErrors={setErrors}
						additionalErrors={additionalErrors}
						setAdditionalErrors={setAdditionalErrors}
						transferRelease={transferRelease}
					/>
				)}

			<button
				className={
					errors.snippet_start || errors.snippet_end
						? `${s.form__submit} ${s.form_submit_withError}`
						: `${s.form__submit}`
				}
				style={
					buttonDisabled || disableSaveBtn ? { opacity: 0.2 } : { opacity: 1 }
				}
				disabled={buttonDisabled || disableSaveBtn}
				onClick={onSaveHandler}
			>
				<FormattedMessage
					id={'rod.release.create.step.recording_info.form_save'}
				/>
			</button>
		</div>
	);
};

export default TracksForm;
