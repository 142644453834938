/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/02/2021, 11:32
 */
import actions from './RU/actions';
import errors from './RU/errors';
import fields from './RU/fields';
import modals from './RU/modals';
import pages from './RU/pages';
// eslint-disable-next-line
const ru = {
	...errors,
	...actions,
	...fields,
	...modals,
	...pages,

	/* Helpers */
	'rod.under_construction': 'Заглушка для страницы',
	'rod.form.required_title': '* обязательные для заполнения поля',
	'rod.label.required_fields': '* обязательные для заполнения поля',
	'rod.autosuggest.nothing_to_show': 'По вашему запросу ничего не найдено',

	'rod.repertoire.banner.header':
		'Здравствуйте, мы рады приветствовать вас в числе партнеров Broma 16',
	'rod.repertoire.banner.body':
		'Для начала предоставления услуги, нам необходимо получить информацию о вашем репертуаре. По ссылкам ниже находится  форма для заполнения репертуара и инструкции, чтобы выполнить заполнение правильно. Пожалуйста  скачайте форму и прочитайте инструкции перед заполнением. Заполненную форму, пожалуйста, загрузите в личном кабинете, нажав на кнопку ЗАГРУЗИТЬ.  Используйте виджет “ПОМОШЬ”, внизу справа, для поиска ответов на возникающие вопросы или для связи со службой поддержки. ',
	'rod.repertoire.banner.action.upload': 'Загрузить',

	'rod.msg.validation.error.phone_not_valid': 'номер телефона введен неверно',
	'rod.user.repertoire.upload.text': '<u>загрузите</u> или перетяните файл',

	'rod.account.create.moderation.declined_documents.header':
		'спасибо за предоставленные данные ',
	'rod.account.create.moderation.declined_documents':
		'При проверке предоставленных вами данных были обнаружены следующие ошибки:',
	'rod.account.create.moderation.declined_documents.todo':
		'Пожалуйста, загрузите корректные копии документов',
	'rod.action.to_documents': 'отправить документы',

	'rod.account.create.moderation.declined_contracts.header':
		'спасибо за подписанные документы',
	'rod.account.create.moderation.declined_contracts':
		'При проверке предоставленных вами документов были обнаружены ошибки. Пожалуйста, исправьте их и загрузите корректные копии документов',
	'rod.action.to_contracts': 'отправить документы',

	'rod.account.create.moderation.error_quality':
		'низкое качество загруженного документа',
	'rod.account.create.moderation.error_invalid':
		'документ не действителен (просрочен или принадлежит другому лицу)',
	'rod.account.create.moderation.error_sign':
		'отсутствует подпись на всех страницах',
	'rod.account.create.moderation.error_pages':
		'документ содержит не все страницы',
	'rod.account.create.moderation.passport_regis': 'паспорт, первая страница',
	'rod.account.create.moderation.passport_address': 'паспорт, вторая страница',
	'rod.account.create.moderation.driving_license_front_page':
		'водительское удостоверение, первая страница',
	'rod.account.create.moderation.driving_license_back_page':
		'водительское удостоверение, вторая страница',

	'rod.account.create.moderation.pending_contract.header': 'спасибо',
	'rod.account.create.confirm.header': 'спасибо!',
	'rod.account.create.confirm.subheader':
		'пожалуйста подтвердите введенные вами данные',
	'rod.account.create.confirm.account': 'Личные данные',
	'rod.account.create.confirm.personal_entrepreneur': 'Данные ИП',
	'rod.account.create.confirm.company': 'Данные компании',
	'rod.account.create.confirm.contacts': 'Контактные данные',
	'rod.account.create.confirm.documents': 'Документы',
	'rod.account.create.confirm.societies': 'Авторские общества',
	'rod.account.create.confirm.identifiers': 'Идентификаторы',
	'rod.account.create.confirm.payments': 'Платежная информация',

	'rod.account.create.confirm.table.title': 'Title',
	'rod.account.create.confirm.table.value': 'Value',
	'rod.field.sex': 'Пол',

	'rod.account.create.confirm.roles': 'Роли',
	'rod.account.create.confirm.scope_group': 'Репертуар',
	'rod.account.create.confirm.business_type': 'Тип аккаунта',

	'rod.document.passport_photo_page': 'Паспорт (страница с фотографией)',
	'rod.document.passport_registration_page':
		'Паспорт (страница с регистрацией)',

	'rod.document.sole_entrepreneur_registration_certificate':
		'Документ о регистрации ИП',
	'rod.document.company.registration_certificate':
		'Документ о регистрации компании',
	'rod.document.company_tax_residency_certificate':
		'Сертификат о налоговом резидентстве',
	'rod.document.company_registration_certificate':
		'Свидетельство о регистрации',
	'rod.account.data.confirm_title': 'подтверждение данных',
	'rod.account.data.confirm_description':
		'Если данные указаны не корректно, пожалуйста, вернитесь в настройки и актуализируйте информацию',
	'rod.account.data.confirm.account': 'персональные данные',
	'rod.account.data.confirm.personal_entrepreneur': 'информация об ИП',
	'rod.account.data.confirm.company': 'информация о компании',
	'rod.account.data.confirm.payments': 'платежная информация',
	'rod.account.data.confirm.tax_form': 'налоговый номер',
	'rod.account.data.confirm.sign_type': 'выберите тип подписания',
	'rod.account.data.confirm.company_address': 'Адрес регистрации компании',

	'rod.account.data.generating_invoice_number':
		'выберите способ генерации номера счета',
	'rod.account.data.generating_invoice_number.auto': 'автоматически',
	'rod.account.data.generating_invoice_number.manual': 'вписать вручную',
	'rod.account.data.invoice.statement_of_reports': 'отчетная ведомость',
	'rod.account.data.invoice.statement_of_reports.subheader':
		'скачайте файлы отчетов, подпишите и загрузите обратно в формате PDF',
	'rod.account.data.invoice.statement_of_reports.download_error':
		'не удалось скачать файл, обратитесь в тех.поддержку',
	'rod.account.data.invoice.statement_of_reports.help':
		'При отсутствии вопросов к отчетам, пожалуйста, подпишите каждый отчет и загрузите обратно. <br><br> Полную сумму вознаграждения за отчетный период вы можете увидеть на первой вкладке отчета "Сводка", на остальных закладках расшифровка указанной суммы по произведениям и фонограммам. <br><br> Подробную информацию по Отчетам и Выплатам вы можете найти в разделе FAQ. <br><br> Пожалуйста, подпишите документы своей подписью, используя стандартный инструмент для подписания документов в Acrobat Reader или другом редакторе PDF, а не функцию добавления комментариев.',
	'rod.multiple.upload.max_count':
		'Максимальное количество файлов к загрузке - {value}',
	'rod.multiple.upload.file.max_size':
		'Максимальный размер файла - 20480 килобайт',
	'rod.multiple.upload.file.file_type':
		'Допустимые форматы файлов - .pdf, .zip. Проверьте формат файла и повторите попытку',
	'rod.multiple.upload.file.required':
		'Загрузите файлы подписанных отчетов в PDF формате',

	'rod.account.invoice-sign.title': 'скачайте и подпишите счет',
	'rod.account.invoice-sign.title_online': 'подпишите счет на оплату онлайн',
	'rod.account.invoice-sign.description':
		'для оплаты счета необходимо подтвердить его, скачать и подписать. Далее загрузить в формате pdf подписанный счет и отправить на модерацию.',
	'rod.account.invoice-sign.invoice_number': 'счет на оплату №{value}',
	'rod.account.invoice-sign.tooltip': `<p>При ручном подписании счета, пожалуйста, используйте стандартный инструмент для подписания документов в Acrobat Reader или другом редакторе PDF, а <b>не функцию добавления комментариев.</b></p>
	`,
	'rod.account.tooltip': `<p>При ручном подписании договора, пожалуйста, используйте стандартный инструмент для подписания документов в Acrobat Reader или другом редакторе PDF, а <b>не функцию добавления комментариев</b>.</p>
		<p>Также <b>подпишите каждую страницу</b> договора снизу - ( Подпись: )</p>
		`,
};

export default ru;
