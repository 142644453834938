import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import isEqual from 'lodash/isEqual';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import MuiMultipleCheckedSkeleton from '../MuiMultipleCheckedSkeleton/MuiMultipleCheckedSkeleton';
import { Label } from 'components/Labels';

// Styles
import s from './MuiMultipleCheckedTracks.module.css';

const MultipleSelectReleases = ({
	rootContext: { getAccountStatisticAllReleases },
	dateFrom,
	dateTo,
	accountId,
	isAdmin,
	releasesState,
	artists,
	lang,
	setResetRecordings,
	setIsGetReleases,
	isResetPerformers,
	setIsResetReleases,
	isLoadedPerformers,
	setIsLoadedReleases,
}) => {
	const [releases, setReleases] = useState([]);
	const [checkedIds, setCheckedIds] = useState([]);
	const [cachedIds, setCachedIds] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isShowSkeleton, setIsShowSkeleton] = useState(false);
	const [nameRelease, setNameRelease] = useState('');
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [cachedDateFrom, setCachedDateFrom] = useState('');
	const [cachedDateTo, setCachedDateTo] = useState('');
	const [cachedArtists, setCachedArtists] = useState([]);

	const [page] = useState(1);
	const [limit] = useState(100);

	const [isReqLive, setIsReqLive] = useState(false);

	const getReleases = (artists) => {
		setIsLoadedReleases(false);
		setIsShowSkeleton(true);
		setIsGetReleases(false);
		getAccountStatisticAllReleases(
			isAdmin,
			accountId,
			searchValue,
			page,
			limit,
			dateFrom,
			dateTo,
			artists
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked: checkedIds.includes(item.heaven11_release_id) ? true : false,
			}));

			const allReleasesButton = {
				heaven11_release_id: 0,
				release_name: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			setCachedDateFrom(dateFrom);
			setCachedDateTo(dateTo);
			setCachedArtists(artists);

			let releasesData;

			if (data.length) {
				releasesData = [allReleasesButton, ...data];
				setIsEmptyData(false);
			} else {
				releasesData = [];
				setIsEmptyData(true);
			}

			if (dateFrom !== cachedDateFrom || dateTo !== cachedDateTo) {
				const newReleases = releasesData.map((item) => ({
					...item,
					checked: checkedIds.includes(item.heaven11_release_id),
				}));

				const checkedReleasesIds = newReleases
					.filter((item) => item.checked)
					?.map((item) => item.heaven11_release_id);
				setCheckedIds(checkedReleasesIds);
				releasesState(checkedReleasesIds.filter((id) => id !== 0));
			} else if (!isEqual(artists, cachedArtists)) {
				const allReleasesIds = releasesData.map(
					(release) => release.heaven11_release_id
				);
				setCheckedIds(allReleasesIds);
				releasesState(allReleasesIds.filter((id) => id !== 0));
			}

			if (isResetPerformers) {
				setCheckedIds([]);
				setReleases([]);
				releasesState([]);
				setSearchValue('');
			}

			setReleases(releasesData);
			setIsShowSkeleton(false);
			setIsLoadedReleases(true);
			setIsGetReleases(true);
		});
	};

	useEffect(() => {
		setIsReqLive(isLoadedPerformers);
	}, [isLoadedPerformers]);

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		if ((searchValue.length || !searchValue.length) && isReqLive) {
			getReleases(artists);
		}
	}, [artists, searchValue, lang, isReqLive]);

	const handleCheckedRelease = (checkedRelease) => {
		setNameRelease(checkedRelease.release_name);
		let mappedReleases;

		if (checkedRelease.heaven11_release_id === 0 && checkedRelease.checked) {
			mappedReleases = releases.map((release) => ({
				...release,
				checked: false,
			}));

			setCheckedIds([]);
		}

		if (checkedRelease.heaven11_release_id === 0 && !checkedRelease.checked) {
			mappedReleases = releases.map((release) => ({
				...release,
				checked: true,
			}));

			setCheckedIds(releases.map((release) => release.heaven11_release_id));
		}

		if (!(checkedRelease.heaven11_release_id === 0) && checkedRelease.checked) {
			mappedReleases = releases.map((release) => {
				if (release.heaven11_release_id === 0) {
					return { ...release, checked: false };
				}

				if (
					release.heaven11_release_id === checkedRelease.heaven11_release_id
				) {
					return { ...release, checked: false };
				} else {
					return release;
				}
			});

			setCheckedIds(
				checkedIds.filter(
					(id) => id !== checkedRelease.heaven11_release_id && id !== 0
				)
			);
		}

		if (
			!(checkedRelease.heaven11_release_id === 0) &&
			!checkedRelease.checked
		) {
			mappedReleases = releases.map((release) => {
				if (release.heaven11_release_id === 0) {
					return { ...release, checked: false };
				}

				if (
					release.heaven11_release_id === checkedRelease.heaven11_release_id
				) {
					return { ...release, checked: true };
				} else {
					return release;
				}
			});

			setCheckedIds([
				...checkedIds.filter((checkedId) => checkedId !== 0),
				checkedRelease.heaven11_release_id,
			]);
		}

		setReleases(mappedReleases);
	};

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	const debouncedSearchHandler = useCallback(
		debounce(handleSearchInput, 500),
		[]
	);

	const handleClickOnly = (e, release) => {
		setNameRelease(release.release_name);
		e.stopPropagation();

		const mappedReleases = releases.map((item) => ({
			...item,
			checked: item.heaven11_release_id === release.heaven11_release_id,
		}));

		setCheckedIds([release.heaven11_release_id]);
		setReleases(mappedReleases);
	};

	const handleSelectClose = () => {
		if (cachedIds !== checkedIds) {
			releasesState(checkedIds.filter((id) => id !== 0));
			setResetRecordings(false);
		}
	};

	const handleSelectOpen = () => {
		setCachedIds(checkedIds);
	};

	const handleResetFilter = () => {
		setCheckedIds([]);
		setReleases([]);
		releasesState([]);
		setIsEmptyData(false);
		setIsResetReleases(true);
		setSearchValue('');
	};

	useEffect(() => {
		if (!releases.length && !checkedIds.length && !isEmptyData && isReqLive) {
			getReleases(artists);
		}
	}, [releases, checkedIds, isEmptyData, isReqLive]);

	const valueForInput = () => {
		if (isShowSkeleton || !isLoadedPerformers) {
			return [0];
		} else if (!checkedIds.length) {
			return [];
		} else if (checkedIds.length) {
			return checkedIds;
		} else {
			return [];
		}
	};

	return (
		<FormControl variant="standard" className={s.formControl}>
			<Input id="releases-select-label">
				<FormattedMessage id="rod.statistic.releases" />
			</Input>
			<SelectCustom
				labelId="releases-select-label"
				id="releases-select"
				multiple
				value={valueForInput()}
				onOpen={handleSelectOpen}
				onClose={handleSelectClose}
				disabled={isShowSkeleton || !isLoadedPerformers}
				MenuProps={{
					style: { zIndex: 99999999999 },
				}}
				renderValue={() => {
					if (checkedIds.length === 1) {
						return <span>{nameRelease}</span>;
					} else if (isShowSkeleton || !isLoadedPerformers) {
						return <FormattedMessage id={'rod.statistic.updating-data'} />;
					} else if (!isShowSkeleton) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} />{' '}
								{checkedIds.includes(0)
									? checkedIds.length - 1
									: checkedIds.length}{' '}
								<FormattedMessage id={'rod.statistic.selected-releases'} />
							</>
						);
					}
				}}
			>
				<FormattedMessage id="rod.statistic.search-releases">
					{(label) => (
						<TextFieldInput
							className={s.search}
							label={label}
							initialValue={searchValue ? searchValue : ''}
							defaultValue={searchValue}
							onChange={debouncedSearchHandler}
							variant="standard"
						/>
					)}
				</FormattedMessage>
				<div className={s.wrapper}>
					{!isShowSkeleton ? (
						releases.map((release) => (
							<MenuItem
								key={release.heaven11_release_id}
								value={release.release_name}
								onClick={() => handleCheckedRelease(release)}
							>
								<CheckboxBlack
									checked={checkedIds.includes(release.heaven11_release_id)}
								/>
								<Label>{release.release_name}</Label>
								<button
									className={s.btnOnly}
									onClick={(e) => handleClickOnly(e, release)}
								>
									<FormattedMessage id={'rod.statistic.only'} />
								</button>
							</MenuItem>
						))
					) : (
						<MuiMultipleCheckedSkeleton />
					)}

					{!releases.length && !isShowSkeleton && (
						<p className={s.noData}>
							<FormattedMessage id={'rod.no_data_select'} />
						</p>
					)}
				</div>
			</SelectCustom>
			<button
				className={s.buttonResetFilter}
				onClick={handleResetFilter}
				style={
					!releases.length && !isShowSkeleton && !isLoadedPerformers
						? { opacity: '0.4', pointerEvents: 'none' }
						: {}
				}
			>
				<FormattedMessage id={'rod.statistic.reset-filter'} />
			</button>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectReleases);
