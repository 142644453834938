// Core
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LangContext } from 'contexts/LangContext';
// Context
import { UIContext } from 'contexts/UIContext';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { Label } from 'components/Labels';
import { FormInput } from 'components/Form/FormInput';
import moment from 'moment';

// Styles
import styles from './DatesBlockNew.module.css';

const DatesBlockNew = ({
	changeField,
	statisticData,
	errors,
	releaseId,
	lastStatDate,
	handleCheckedPeriod,
	setApplyDate,
	loading,
	applyDateFilter,
	setIsLastCalendar,
}) => {
	const MIN_DATE = '2020-01-01';
	const currentDay = new Date(new Date().getTime() - 86400000 * 2)
		.toISOString()
		.split('T')[0];

	const { lang } = useContext(LangContext);
	const [showCalendar, setShowCalendar] = useState(false);
	const labelSelectItem = [
		{ period: 'week', ruLabel: 'последние 7 дней', enLabel: 'last 7 days' },
		{ period: 'month', ruLabel: 'последний месяц', enLabel: 'last month' },
		{
			period: 'halfYear',
			ruLabel: 'последние 6 месяцев',
			enLabel: 'last 6 months',
		},
		{
			period: 'year',
			ruLabel: 'последние 12 месяцев',
			enLabel: 'last 12 months',
		},
		{
			period: 'allPeriod',
			ruLabel: 'за все время',
			enLabel: 'all time',
		},
		{
			period: 'customPeriod',
			ruLabel: 'выбрать период',
			enLabel: 'select period',
		},
	];

	const [checkedItemPeriod, setCheckedPeriodItem] = useState(
		labelSelectItem[1].period && lang === 'ru'
			? labelSelectItem[1].ruLabel
			: labelSelectItem[1].enLabel
	);

	const handleCheckedPeriodItem = (event, period) => {
		setCheckedPeriodItem(event);

		switch (period) {
			case 'week':
				const weekStart = moment().subtract(8, 'days').format('YYYY-MM-DD');
				handleCheckedPeriod(weekStart, currentDay);
				break;
			case 'month':
				const monthStart = moment()
					.subtract(1, 'month')
					.subtract(2, 'days')
					.format('YYYY-MM-DD');
				handleCheckedPeriod(monthStart, currentDay);
				break;
			case 'halfYear':
				const halfYear = moment()
					.subtract(6, 'months')
					.subtract(2, 'days')
					.format('YYYY-MM-DD');
				handleCheckedPeriod(halfYear, currentDay);
				break;
			case 'year':
				const year = moment()
					.subtract(1, 'year')
					.subtract(2, 'days')
					.format('YYYY-MM-DD');
				handleCheckedPeriod(year, currentDay);
				break;
			case 'allPeriod':
				handleCheckedPeriod(MIN_DATE, currentDay);
				break;
			case 'customPeriod':
				setShowCalendar(true);
				setApplyDate(false);
				break;
			default:
				break;
		}
	};

	const applyDateCalendar = () => {
		const startDate = statisticData.start_date.split('-').reverse().join('.');
		const endDate = statisticData.end_date.split('-').reverse().join('.');
		setShowCalendar(false);
		setApplyDate(true);
		setCheckedPeriodItem(`${startDate} - ${endDate}`);
		applyDateFilter(statisticData.start_date, statisticData.end_date);
	};

	if (showCalendar) {
		setTimeout(() => {
			const calendars = document.querySelectorAll('[role="dialog"]');
			if (calendars.length) {
				calendars[0].addEventListener('click', () => {
					setIsLastCalendar(false);
				});
				calendars[1].addEventListener('click', () => {
					setIsLastCalendar(true);
				});
			}
		}, 300);
	}

	return (
		<FormControl variant="standard" className={styles.inputPeriodWrapper}>
			<Input id="period-select-label">
				<FormattedMessage id="rod.statistic.period-selected" />
			</Input>
			<div className={styles.wrapperCalendar}>
				<SelectCustom
					disabled={loading}
					labelId="period-select-label"
					id="period-select"
					value={labelSelectItem}
					className={styles.inputPeriod}
					onOpen={() => setShowCalendar(false)}
					MenuProps={{
						style: { zIndex: 1000 },
					}}
					renderValue={() => {
						return <span>{checkedItemPeriod}</span>;
					}}
				>
					<div className={styles.wrapperMenuItem}>
						{labelSelectItem.map(({ period, ruLabel, enLabel }) => (
							<MenuItem
								sx={{
									p: '13px 20px',
								}}
								key={period}
								className={styles.selectItem}
								value={period}
								onClick={() =>
									handleCheckedPeriodItem(
										lang === 'ru' ? ruLabel : enLabel,
										period
									)
								}
							>
								{period === 'customPeriod' && (
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className={styles.iconCustomPeriod}
									>
										<path
											d="M11.3335 2.00033H14.0002C14.177 2.00033 14.3465 2.07056 14.4716 2.19559C14.5966 2.32061 14.6668 2.49018 14.6668 2.66699V13.3337C14.6668 13.5105 14.5966 13.68 14.4716 13.8051C14.3465 13.9301 14.177 14.0003 14.0002 14.0003H2.00016C1.82335 14.0003 1.65378 13.9301 1.52876 13.8051C1.40373 13.68 1.3335 13.5105 1.3335 13.3337V2.66699C1.3335 2.49018 1.40373 2.32061 1.52876 2.19559C1.65378 2.07056 1.82335 2.00033 2.00016 2.00033H4.66683V0.666992H6.00016V2.00033H10.0002V0.666992H11.3335V2.00033ZM10.0002 3.33366H6.00016V4.66699H4.66683V3.33366H2.66683V6.00033H13.3335V3.33366H11.3335V4.66699H10.0002V3.33366ZM13.3335 7.33366H2.66683V12.667H13.3335V7.33366Z"
											fill="currentColor"
										/>
									</svg>
								)}

								<Label>{lang === 'ru' ? ruLabel : enLabel}</Label>
							</MenuItem>
						))}
					</div>
				</SelectCustom>
				{showCalendar && (
					<div className={styles.calendar}>
						<div className={styles.wrapperInputsDate}>
							<div className={styles.inputDate}>
								<FormInput
									className={styles.date}
									type={'muiInputDate_v3'}
									defaultValue={true}
									name={'start_date'}
									onChange={changeField}
									minDate={Date.parse(MIN_DATE)}
									maxDate={Date.parse(statisticData.end_date)}
									errors={errors}
									data={statisticData}
									label={<FormattedMessage id={'rod.statistic.start-date'} />}
									disabled={releaseId && !lastStatDate}
									open={true}
								/>
								{Object.keys(errors).includes('start_date') && (
									<div className={styles.error}>
										<span className={styles.errorText}>
											{errors['start_date'] === 'invalid date' ? (
												<FormattedMessage id={'rod.admin.invalid-date'} />
											) : (
												<FormattedMessage id={'rod.error.statistic.minDate'} />
											)}
											{errors['start_date'] === 'minDate' && MIN_DATE}
										</span>
									</div>
								)}
							</div>
							<div className={styles.inputDate}>
								<FormInput
									className={styles.date}
									type={'muiInputDate_v3'}
									defaultValue={true}
									name={'end_date'}
									onChange={changeField}
									minDate={Date.parse(statisticData.start_date)}
									maxDate={Date.now()}
									errors={errors}
									data={statisticData}
									label={<FormattedMessage id={'rod.statistic.end-date'} />}
									disabled={releaseId && !lastStatDate}
									open={true}
								/>
								{Object.keys(errors).includes('end_date') && (
									<div className={styles.error}>
										<span className={styles.errorText}>
											{errors['end_date'] === 'invalid date' ? (
												<FormattedMessage id={'rod.admin.invalid-date'} />
											) : (
												''
											)}
										</span>
									</div>
								)}
							</div>
						</div>
						<div className={styles.wrapperButtonCalendar}>
							{statisticData.start_date === statisticData.end_date && (
								<span className={styles.validateDate}>
									<FormattedMessage id={'rod.admin.invalid-date'} />
								</span>
							)}
							<div className={styles.buttonsCalendar}>
								<button
									className={styles.buttonCalendarCancel}
									onClick={() => setShowCalendar(false)}
								>
									<FormattedMessage id={'rod.loading_repertoire_file.cancel'} />
								</button>
								<button
									className={styles.buttonCalendarApply}
									onClick={applyDateCalendar}
									style={
										statisticData.start_date === statisticData.end_date
											? { opacity: '0.2', pointerEvents: 'none' }
											: {}
									}
								>
									<FormattedMessage id={'rod.release.info.apply'} />
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</FormControl>
	);
};

export default DatesBlockNew;
