// Core
import moment from 'moment';

export const changeSnippet = (
	field,
	selectedRelease,
	errors,
	setErrors,
	additionalReleases,
	setAdditionalReleases
) => (e) => {
	delete errors.snippet_start;
	delete errors.snippet_end;
	delete errors.snippet_end_start;
	let endTime;

	const { release_type_id } = selectedRelease;

	const minutes = parseInt(e.target.value.split(':')[0]);
	const seconds = parseInt(e.target.value.split(':')[1]);

	if (minutes > 59 || seconds > 59) {
		return;
	}

	const updatedSelectedRelease = { ...selectedRelease };
	updatedSelectedRelease.snippet_start =
		field === 'snippet_start'
			? e.target.value
			: updatedSelectedRelease.snippet_start;

	if (field === 'snippet_start') {
		switch (release_type_id) {
			case 43:
				endTime = moment(e.target.value, 'mm:ss:SSS')
					.add(29.99, 'seconds')
					.format('mm:ss:SSS');
				break;
			case 70:
				if (minutes === 59) {
					endTime = e.target.value;
				} else {
					endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
						.add(60, 'seconds')
						.format('mm:ss:SSS');
				}
				break;
			default:
				break;
		}
	}

	updatedSelectedRelease.snippet_end =
		field === 'snippet_start' ? endTime.toString() : e.target.value;

	if (
		moment(updatedSelectedRelease.snippet_end, 'mm:ss:SSS').isBefore(
			moment(updatedSelectedRelease.snippet_start, 'mm:ss:SSS')
		)
	) {
		setErrors({ snippet_end_start: [{ rule: 'date_format' }] });
	}

	const releaseIndex = additionalReleases.findIndex(
		(release) => release.id === selectedRelease.id
	);

	const updatedReleases = [...additionalReleases];
	updatedReleases[releaseIndex] = updatedSelectedRelease;
	setAdditionalReleases(updatedReleases);
};
