// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withAuth, withRoot, withUI } from 'hocs';
import moment from 'moment';
import { format } from 'date-fns';
import { compose } from 'recompose';

// Context
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { getAuthor } from './utils/getAuthor';
import { getHistoryStatuses } from './utils/getHistoryStatuses';
import { typeLocalization } from './utils/typeLocalization';

// UI
import { Loading } from 'components';
import { Pagination } from 'material-design/components';
import Thead from './Thead/Thead';
import NoData from './NoData/NoData';

// Styles
import styles from './StatusHistory.module.css';

function StatusHistory(props) {
	const { id, isSignup } = props;
	const { lang } = useContext(LangContext);
	const { getAdminSteps, getSignupsSteps } = useContext(RootContext);

	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [historyData, setHistoryData] = useState([]);
	const [page, setPage] = useState(1);
	const [openAccordion, setOpenAccordion] = useState(null);

	moment.locale(lang);

	const handleAccordionToggle = (index) => {
		setOpenAccordion(openAccordion === index ? null : index);
	};

	useEffect(() => {
		switch (isSignup) {
			case true:
				getSignupsSteps(id, page)
					.then((res) => {
						setHistoryData(res.data);
						setTotal(res.total);
						setLoading(false);
					})
					.catch((err) => console.log.log(err));
				break;
			case false:
				getAdminSteps(id, page)
					.then((res) => {
						setHistoryData(res.data);
						setTotal(res.total);
						setLoading(false);
					})
					.catch((err) => console.log.log(err));
				break;
			default:
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	return (
		<>
			<div className={styles.page}>
				<table className={styles.Table}>
					<Thead />
					{loading ? (
						<tr className={styles.loaderWrapper}>
							<td colspan="4">
								<Loading className={styles.loader} />
							</td>
						</tr>
					) : (
						<>
							{historyData?.length === 0 && <NoData />}
							{historyData?.length > 0 &&
								historyData.map((item, index) => (
									<tr>
										<td
											className="firstCol"
											style={{
												display: 'flex',
												paddingTop: '5px',
												height: openAccordion === index ? 'auto' : '50px',
											}}
										>
											<div className={styles.chevronContainer}>
												<div
													className={styles.chevronRow}
													onClick={() => handleAccordionToggle(index)}
												>
													{item.action?.outlets && (
														<>
															{openAccordion === index ? (
																<i className="fa fa-caret-up" />
															) : (
																<i className="fa fa-caret-down" />
															)}
														</>
													)}
													<span style={{ marginLeft: '8px' }}>
														<div>
															{(!isSignup ||
																item.action === 'signup.status_change') && (
																<>{getHistoryStatuses(item)}</>
															)}
															{isSignup &&
																[
																	'signup.phone.manual_verification',
																	'signup.email.manual_verification',
																].includes(item.action) && (
																	<>
																		<FormattedMessage
																			id={`rod.admin.${item.action}`}
																		/>
																	</>
																)}
														</div>
													</span>
												</div>
												{openAccordion === index && (
													<div className={styles.outlets}>
														{item.action?.outlets?.map((item) => (
															<div>{item[lang]}</div>
														))}
													</div>
												)}
											</div>
										</td>
										<td>
											<div className={styles.flexAlign}>
												{format(new Date(item.updated_at), 'HH:mm dd.MM.yyyy')}
											</div>
										</td>
										<td>
											<div className={styles.flexAlign}>
												{typeLocalization(item.type)}
											</div>
										</td>
										<td className={styles.author}>
											<div className={styles.flexAlign}>{getAuthor(item)}</div>
										</td>
									</tr>
								))}
						</>
					)}
				</table>
				{!loading && total > 20 && (
					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => {
							setPage(pageNumber);
							setOpenAccordion(null);
						}}
						items_on_page={20}
					/>
				)}
			</div>
		</>
	);
}

export default compose(withRoot, withAuth, withUI, withRouter)(StatusHistory);
