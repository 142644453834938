// Core
import { FormattedMessage } from 'react-intl';

export const checkSnippetErrors = (field, errors, releaseTypeId) => {
	switch (field) {
		case 'snippet_start':
			return (
				<FormattedMessage
					id={`rod.release_create_snipped.${errors.snippet_start[0].rule}`}
				/>
			);
			case 'snippet_end':
				return (
					<FormattedMessage
						id={
							releaseTypeId === 43
								? 'rod.release_create_snipped.ringtone.end'
								: 'rod.release_create_snipped.tiktok.end'
						}
					/>
				);

		default:
			break;
	}
};
