// Core
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Utils
import { changeSnippet } from '../../utils/changeSnippet';

// UI
import { InfoTitle } from 'components';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

// Styles
import styles from './Fragment.module.css';

const Fragment = ({
	errors,
	releaseTypeId,
	additionalRelease,
	additionalErrors,
	setAdditionalErrors,
	additionalReleases,
	setAdditionalReleases,
}) => {
	const checkSnippetErrors = (field) => {
		switch (field) {
			case 'snippet_start':
				return (
					<FormattedMessage
						id={`rod.release_create_snipped.${additionalErrors.snippet_start[0].rule}`}
					/>
				);
			case 'snippet_end':
				return (
					<FormattedMessage
						id={
							releaseTypeId === 43
								? 'rod.release_create_snipped.ringtone.end'
								: 'rod.release_create_snipped.tiktok.end'
						}
					/>
				);

			default:
				break;
		}
	};

	return (
		<>
			<InfoTitle
				title="rod.release.create.step.tracks.fragment.title"
				helpTitle="rod.release.create.step.tracks.fragment.help"
			/>
			<div className={`${styles.form__section} ${styles.third__section}`}>
				<div className={styles.input}>
					<InputMask
						mask={'99:99:999'}
						maskChar={'0'}
						name={'snippet_start'}
						onChange={changeSnippet(
							'snippet_start',
							additionalRelease,
							additionalErrors,
							setAdditionalErrors,
							additionalReleases,
							setAdditionalReleases
						)}
						errors={additionalErrors}
						value={
							additionalRelease.snippet_start
								? additionalRelease.snippet_start
								: ''
						}
					>
						{() => (
							<TextFieldInput
								type="muiInput"
								variant="standard"
								name={'snippet_start'}
								required
								errors={additionalErrors}
								value={
									additionalRelease.snippet_start
										? additionalRelease.snippet_start
										: ''
								}
								label={
									<FormattedMessage
										id={'rod.release.create.step.tracks.fragment.start'}
									/>
								}
							/>
						)}
					</InputMask>
					{additionalErrors.id === additionalRelease.id &&
						Object.keys(additionalErrors).includes('snippet_start') && (
							<span className={styles.helper}>
								{checkSnippetErrors('snippet_start')}
							</span>
						)}
				</div>
				<div className={styles.input}>
					<InputMask
						mask={'99:99:999'}
						maskChar={'0'}
						name={'snippet_end'}
						onChange={changeSnippet(
							'snippet_end',
							additionalRelease,
							additionalErrors,
							setAdditionalErrors,
							additionalReleases,
							setAdditionalReleases
						)}
						errors={additionalErrors}
						value={
							additionalRelease.snippet_end ? additionalRelease.snippet_end : ''
						}
					>
						{() => (
							<TextFieldInput
								type="muiInput"
								variant="standard"
								name={'snippet_end'}
								errors={additionalErrors}
								value={
									additionalRelease.snippet_end
										? additionalRelease.snippet_end
										: ''
								}
								label={
									<FormattedMessage
										id={'rod.release.create.step.tracks.fragment.end'}
									/>
								}
							/>
						)}
					</InputMask>
					{Object.keys(additionalErrors).includes('snippet_end_start') && (
						<span className={styles.helper}>
							<FormattedMessage id={'rod.release_create_snipped.date_format'} />
						</span>
					)}
				</div>
			</div>
			{errors && Object.keys(errors).includes('snippet_end') && (
				<span className={styles.snippetError}>
					{checkSnippetErrors('snippet_end')}
				</span>
			)}
		</>
	);
};

export default Fragment;
